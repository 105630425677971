import axios from 'axios'
import * as React from 'react'
import { getApiUrl, getTenantFromDomainName, getWssUrl } from '../../components/api/Environment'
import { useWatchListingSocket } from '../../components/websocket'
import { firebaseEnv } from '../../utils/firebase'

// markup
const Page = () => {
  const [wsstatus, setWsstatus] = React.useState('connecting...')

  const [backend, setBackend] = React.useState({})

  React.useEffect(() => {
    axios.get(getApiUrl() + '/status').then(res => setBackend(res.data))
  }, [])

  useWatchListingSocket({ listingId: '1', handleStatusUpdate: setWsstatus, refreshEntireListing: false, isVendor: true })

  return (<>
    <pre>api={getApiUrl()}</pre>
    <pre>wss={getWssUrl()}</pre>
    <pre>tenant={getTenantFromDomainName()}</pre>
    <pre>websocket={wsstatus}</pre>
    <pre>firebase={firebaseEnv?.projectId}</pre>
    <pre>backend={JSON.stringify(backend, null, 2)}</pre>
  </>)
}

export default Page
